<template>
  <li>
    <NuxtLink :to="route">
      <div :class="getLinkClass()" class="relative" @mouseenter="isTooltipVisible = true" @mouseleave="isTooltipVisible = false">
        <OptiagoIcon class="fill-gray-800" :name="'optiago:' + icon" size="20px" mode="svg" />
        <div
          class="transition-opacity absolute z-10 py-2 px-3.5 bg-gray-900 text-sm text-white rounded shadow-sm left-[64px] max-w-64 whitespace-nowrap"
          :class="{ 'opacity-100 visible': isTooltipVisible, 'opacity-0 invisible': !isTooltipVisible }"
        >
          {{ label }}
        </div>
      </div>
    </NuxtLink>
  </li>
</template>

<script setup lang="ts">
  import OptiagoIcon from '~/components/ui/OptiagoIcon.vue';

  const props = defineProps<{
    label: string;
    isActive: boolean;
    icon: string;
    route: string;
  }>();

  const isTooltipVisible = ref(false);
  const getLinkClass = () => {
    const classList = ['flex', 'items-center', 'py-2.5', 'px-3', 'text-base', 'text-gray-700', 'font-semibold', 'rounded-lg', 'hover:bg-gray-100', 'justify-center'];

    if (props.isActive) {
      classList.push('bg-gray-200');
    }

    return classList.join(' ');
  };
</script>
