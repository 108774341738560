<template>
  <div id="docs-sidebar" class="overflow-visible hs-overlay fixed top-0 start-0 bottom-0 z-[60] bg-white border-e border-gray-200 pt-4">
    <div class="flex flex-col h-full">
      <div class="flex flex-col items-center">
        <div class="px-2.5">
          <a class="text-xl font-semibold" href="#" aria-label="Brand">
            <img src="/img/icon-collapse.svg" alt="Logo nomad" />
          </a>
        </div>
      </div>
      <nav class="flex flex-col pt-4" data-hs-accordion-always-open>
        <ul class="space-y-2.5 px-2.5">
          <MenuItem
            v-for="route in routes"
            :key="route.name"
            :route="route.route"
            :label="route.label"
            :is-active="route.activeNames.includes(currentRoute.name.toString())"
            :icon="route.icon"
          />
        </ul>
      </nav>

      <div class="grow flex flex-col justify-end relative">
        <MenuDropdown v-if="menuDropdownDTO" :menu-dropdown-dto="menuDropdownDTO" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import MenuDropdown from '~/components/common/menu/MenuDropdown.vue';
  import { toDTO } from '~/services/common/menu/MenuDropdownMapper';
  import { useUserStore } from '~/stores/api/UserStore';
  import { useTransportOrganizerStore } from '~/stores/api/TransportOrganizerStore';
  import { DateTime } from 'luxon';

  const { currentRoute } = useRouter();
  const { user } = storeToRefs(useUserStore());
  const { currentTransportOrganizer } = storeToRefs(useTransportOrganizerStore());
  const menuDropdownDTO = computed(() => (user.value && currentTransportOrganizer.value ? toDTO(user.value, currentTransportOrganizer.value) : null));

  const defaultWeekNumber = getWeekNumber(DateTime.now());

  const routes = [
    { route: '/structures', label: 'Structure', name: 'structures', icon: 'building', activeNames: ['structures', 'structures-id', 'structures-create'] },
    {
      route: '/passengers',
      label: 'Usagers',
      name: 'passengers',
      icon: 'team',
      activeNames: [
        'passengers',
        'passengers-id',
        'passengers-create',
        'passengers-id-edit',
        'passengers-id-transport-requests-create',
        'passengers-id-transport-requests-transportrequestid',
        'passengers-id-addresses-create',
        'passengers-id-addresses-addressid'
      ]
    },
    { route: '/drivers', label: 'Chauffeurs', name: 'drivers', icon: 'driver', activeNames: ['drivers', 'drivers-id', 'drivers-create'] },
    { route: '/transport-requests', label: 'Trajets', name: 'transport-request', icon: 'guide', activeNames: ['transport-requests'] },
    { route: '/vehicles', label: 'Véhicules', name: 'vehicles', icon: 'bus', activeNames: ['vehicles', 'vehicles-id', 'vehicles-create'] },
    {
      route: '/planification?week=' + defaultWeekNumber + '&filter=1',
      label: 'Planification',
      name: 'planification',
      icon: 'calendar',
      activeNames: ['planification', 'itineraries-days']
    },
    {
      route: '/grouping-points',
      label: 'Point de rendez-vous',
      name: 'grouping-point',
      icon: 'map-pin',
      activeNames: ['grouping-points', 'grouping-points-id', 'grouping-points-create']
    },
    { route: '/parkings', label: 'Parking', name: 'parkings', icon: 'parking', activeNames: ['parkings', 'parkings-id', 'parkings-create'] },
    { route: '/parameters', label: 'Paramètres', name: 'parameters', icon: 'gear', activeNames: ['parameters'] }
  ];
</script>
