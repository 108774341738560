<template>
  <div
    id="dropdown-menu"
    class="hs-dropdown relative w-full [--placement:right] border-t border-gray-200 cursor-pointer"
    @mouseenter="isTooltipVisible = true"
    @mouseleave="isTooltipVisible = false"
  >
    <div
      id="hs-dropdown-with-title"
      class="justify-center flex-row hs-dropdown-toggle w-full py-3 px-4 inline-flex items-center gap-x-2 text-sm font-medium bg-white text-gray-800 hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none"
      data-cy-menu-dropdown-user
    >
      <div class="bg-gray-200 p-2 rounded-full w-9">
        <img src="/img/icon-user.svg" alt="Icone utilisateur" />
      </div>
    </div>
    <div class="hs-dropdown-menu transition-[opacity,margin] duration hs-dropdown-open:opacity-100 opacity-0 hidden" aria-labelledby="hs-dropdown-with-title">
      <div class="mb-2 border-gray-200 border min-w-60 bg-white shadow-md rounded-lg p-2 divide-y divide-gray-200">
        <template v-if="menuDropdownDto.items.length > 1">
          <div class="py-2 first:pt-0 last:pb-0" data-cy-menu-dropdown-user-select-transport-organizer>
            <template v-for="(item, index) in menuDropdownDto.items" :key="index">
              <a
                class="max-w-80 flex items-center gap-x-3.5 py-2 px-3 rounded-lg text-sm text-gray-800 focus:outline-none focus:bg-gray-100"
                href="#"
                :class="item.id == menuDropdownDto.currentItem.id ? 'font-bold cursor-default' : 'hover:bg-gray-100 cursor-pointer'"
                :data-cy-menu-dropdown-user-select-transport-organizer-choice="item.transportOrganizerName"
                @click="
                  () => {
                    setCurrentTransportOrganizer(item.id);
                  }
                "
              >
                <div class="bg-gray-200 p-2 rounded-full w-8">
                  <img src="/img/icon-user.svg" alt="Icone utilisateur" />
                </div>
                <BaseTruncateText :content="item.transportOrganizerName" />
                <div v-if="item.id === menuDropdownDto.currentItem.id" class="grow flex flex-row justify-end">
                  <span class="icon-check-mark" />
                </div>
              </a>
            </template>
          </div>
        </template>
        <div class="py-2 first:pt-0 last:pb-0">
          <a
            href="#"
            class="text-red-500 flex items-center py-2 px-3 rounded-md text-sm font-medium hover:bg-gray-100 focus:outline-none focus:bg-gray-100"
            data-cy-menu-dropdown-user-logout
            @click.prevent="logout"
          >
            <div class="flex justify-center items-center gap-2">
              <OptiagoIcon class="fill-current" name="optiago:logout" />
              <span>Se déconnecter</span>
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>

  <div
    class="transition-opacity absolute z-10 py-2 px-3.5 bg-gray-900 text-sm text-white rounded shadow-sm left-[78px] bottom-2 max-w-64 whitespace-nowrap"
    :class="isTooltipVisible && !isDropdownOpen ? 'opacity-100 visible' : 'opacity-0 invisible'"
  >
    <div class="">
      <div class="font-bold truncate ...">{{ menuDropdownDto.currentItem.transportOrganizerName }}</div>
      <div>{{ menuDropdownDto.email }}</div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { useAuthStore } from '~/stores/api/AuthStore';
  import { useTransportOrganizerStore } from '~/stores/api/TransportOrganizerStore';
  import type MenuDropdownDTO from '~/dto/common/menu/MenuDropdownDTO';
  import { useUserStore } from '~/stores/api/UserStore';
  import type TransportOrganizerResource from '~/api/resource/TransportOrganizerResource';
  import { useGlobalLoadingStore } from '~/stores/common/GlobalLoadingStore';
  import BaseTruncateText from '~/components/ui/BaseTruncateText.vue';
  import OptiagoIcon from '~/components/ui/OptiagoIcon.vue';

  const transportOrganizerStore = useTransportOrganizerStore();
  const { setLoading } = useGlobalLoadingStore();
  const authStore = useAuthStore();
  const { user } = storeToRefs(useUserStore());

  defineProps<{ menuDropdownDto: MenuDropdownDTO }>();

  const isTooltipVisible = ref(false);
  const isDropdownOpen = ref(false);

  onMounted(async () => {
    setTimeout(() => {
      window.HSStaticMethods.autoInit();
    }, 100);

    if (import.meta.client) {
      const module = await import('preline');
      const hsDropdown = module.HSDropdown;

      const element = document.querySelector('#dropdown-menu');
      if (element) {
        hsDropdown.on('open', element as HTMLElement, () => (isDropdownOpen.value = true));
        hsDropdown.on('close', element as HTMLElement, () => (isDropdownOpen.value = false));
      }
    }
  });

  const setCurrentTransportOrganizer = async (id: number) => {
    setLoading(true);
    const transportOrganizers = user?.value?.transportOrganizers as TransportOrganizerResource[];
    const newCurrentTransportOrganizer = transportOrganizers.find((transportOrganizer) => transportOrganizer.id === id);

    if (!newCurrentTransportOrganizer) {
      setLoading(false);
      throw Error('Cannot find current transport organizer');
    }

    await transportOrganizerStore.set(newCurrentTransportOrganizer);
    reloadNuxtApp({
      force: true
    });
  };

  const logout = async () => {
    await authStore.logout();

    navigateTo('/login');
  };
</script>
