<template>
  <div class="text-gray-800">
    <Menu />
    <div class="duration-300 ps-16">
      <ClientOnly>
        <LoadingContainer v-if="loading" />
      </ClientOnly>
      <div v-show="!loading" class="bg-gray-50">
        <slot />
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
  import Menu from '~/components/common/menu/Menu.vue';
  import { useGlobalLoadingStore } from '~/stores/common/GlobalLoadingStore';
  import LoadingContainer from '~/components/common/loading/LoadingContainer.vue';

  const { setLoading } = useGlobalLoadingStore();
  const { loading } = storeToRefs(useGlobalLoadingStore());
</script>
